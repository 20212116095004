
import { defineComponent, ref } from 'vue';
import useModel from '@/hooks/useModel';
export default defineComponent({
    emits: ['upload'],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        enableUpload: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, { emit }) {
        const visibleModel = useModel(props, 'visible');

        const showUpload = ref(true);
        const percent = ref(0);
        const uploadFlag = ref(true);
        const file = ref();
        const handleClose = () => {
            showUpload.value = true;
            file.value = undefined;
            uploadFlag.value = false;
            percent.value = 0;
            visibleModel.value = false;
        };
        const uploadRequest = (source: any) => {
            showUpload.value = false;
            if (source.file) {
                uploadFlag.value = false;
            } else {
                uploadFlag.value = true;
            }
            file.value = source.file;
        };
        const handleUpload = async () => {
            try {
                await new Promise<unknown>((resolve, reject) => {
                    emit('upload', file.value, resolve, reject, {
                        percent,
                    });
                })
                    .then(() => {
                        // showUpload.value = false;
                        // file.value = undefined;
                        // uploadFlag.value = true;
                        percent.value = 100;
                    })
                    .then(() => {
                        setTimeout(() => {
                            handleClose();
                        }, 1000);
                    });
            } catch (e) {
                showUpload.value = true;
                file.value = undefined;
                uploadFlag.value = true;
                percent.value = 0;
            }
        };
        return {
            visibleModel,
            file,
            handleClose,
            uploadRequest,
            handleUpload,
            showUpload,
            uploadFlag,
            percent,
        };
    },
});
